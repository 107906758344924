import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import Generate from './pages/Generate';
import Redirector from './pages/Redirector';
import Contact from './components/Contact';
import Report from './components/Report-link';
import About from './components/About';
import Privacy from './components/Privacy';
import TermsOfService from './components/TermsOfService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Simulate delay before stopping loading
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  // Redirect to /create when loading is complete and the root URL ("/") is accessed
  useEffect(() => {
    if (!loading && window.location.pathname === '/') {
      navigate('/generate/url');
    }
  }, [loading, navigate]);

  return (
    <div className="App">
      <ToastContainer />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Header />
          <Routes>
            <Route path="/generate/url" element={<Generate />} />
            <Route path="/:custom" element={<Redirector />} />
            <Route path="/ContactUs" element={<Contact />} />
            <Route path="/ReportLink" element={<Report />} />
            <Route path="/About" element={<About />} />
            <Route path="/TermsOfService" element={<TermsOfService />} />
            <Route path="/Privacy" element={<Privacy />} />
          </Routes>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default App;
