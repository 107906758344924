/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Modal from 'react-modal';

function Popup(props) {
  const [isClosing, setIsClosing] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#11c5c6',
      border: '1px solid #ddd',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '20px',
    },
  };

  const versionBoxStyle = {
    background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)',
    color: 'white',
    borderRadius: '20px',
    padding: '5px 5px',
    display: 'inline',
  };

  const versionTextStyle = {
    color: 'white',
  };

  const closeButtonStyle = {
    // background: 'linear-gradient(141deg, #fa80d2 34%, #fed757 100%, #C6168D 200%)',
    background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)',
    color: 'white',
    borderRadius: '20px',
    padding: '5px 16px',
    margin: '10px 0',
    cursor: 'pointer',
  };

  const handleButtonClick = () => {
    if (!isClosing) {
      setIsClosing(true);

      // Call onClose after a delay
      setTimeout(() => {
        setIsClosing(false);
        props.onClose();
      }, 300);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={false}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <h2 style={{ margin: '0', marginRight: '10px' }}>
          {props.content}
        </h2>
        
      </div>
      <button onClick={handleButtonClick} style={closeButtonStyle}>
        {isClosing ? 'Closing...' : 'Close'}
      </button>
    </Modal>
  );
}

export default Popup;
