import React from 'react';

const TermsOfService = () => {
    return (
        <div>
          <h1 className="text-2xl font-bold text-center mt-8">
            <span>Terms of Service</span>
          </h1>
          <div className="m-4 p-4 max-w-sm text-left rounded-lg mx-auto">
            <ul className="list-disc pl-4">
              <li>Read the terms of service carefully before using the Web Shorten URL.</li>
              <li>URL Shortener transforms links into shortened links for sharing.</li>
              <li>
                <strong>Conditions of Use:</strong>
                <ul className="list-disc pl-4">
                  <li>Short URLs without at least one click per month will be disabled.</li>
                  <li>Avoid creating links leading to phishing, malware, or explicit content.</li>
                </ul>
              </li>
              <li>
                <strong>Premium Service:</strong>
                <ul className="list-disc pl-4">
                  <li>Offers additional features for promotions, ads, and detailed statistics.</li>
                  <li>Premium plan provides greater control over shortened links.</li>
                </ul>
              </li>
              <li>
                <strong>Disclaimer:</strong>
                <ul className="list-disc pl-4">
                  <li>Free service has limitations; no guarantee of uninterrupted, secure, or error-free services.</li>
                  <li>Users are responsible for verifying information and results.</li>
                </ul>
              </li>
              <li>
                <strong>User Responsibility:</strong>
                <ul className="list-disc pl-4">
                  <li>Assume personal responsibility for actions and conduct due diligence before implementing plans.</li>
                </ul>
              </li>
              {/* Include the rest of the points similarly */}
              <li>
                <strong>Terms Updates:</strong> Web Shorten URL reserves the right to update or change these terms of use at any time; the most current version will always be available on this page.
              </li>
            </ul>
          </div>
        </div>
      );
    };

export default TermsOfService;
