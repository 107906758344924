import React from "react";

function Loading() {
  return (
    <div class="loading-container">
    <div class="loading"></div>
    <div id="loading-text">loading...</div>
</div>

  );
}

export default Loading;
