import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const header = document.getElementById('header');
    if (header) {
      if (window.scrollY > 0) {
        header.style.backgroundColor = 'white';
      } else {
        header.style.backgroundColor = 'transparent';
      }
    }
  };

  const linkStyle = {
    color: 'black',
    marginBottom: '10px', // Bottom spacing only in mobile mode
  };

  const mobileLinkStyle = {
    ...linkStyle,
    marginBottom: '15px', // Add additional spacing for mobile links
  };

  const desktopContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end', // Align elements to the right
  };

  const desktopLinkStyle = {
    ...linkStyle,
    margin: '0 10px', // Add horizontal spacing in desktop mode
  };

  return (
    <header
      id="header"
      className="text-gray-500 px-6 py-4 md:px-12 rounded-t-3xl mt-2 sticky top-0 z-10"
      style={{ borderBottom: '2px solid #e0e0e0' }}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            onClick={() => navigate('/')}
            className="w-12 h-12 rounded-full cursor-pointer mt-2"
            src={require('./img/favicon.png')}
            alt="Icon Web Shorten URL"
          />
          <div className="ml-2">
            <h1 className="text-2xl font-semibold text-gray-400 m-0" style={{ color: 'black' }}>
              Web Shorten URL
            </h1>
          </div>
        </div>

        <div className="hidden md:flex space-x-5">
          <div style={desktopContainerStyle}>
            <a href="/" className="text-gray-400" style={desktopLinkStyle}>
              Home
            </a>
            <a href="/About" className="text-gray-400" style={desktopLinkStyle}>
              About
            </a>
            <a href="/ContactUs" className="text-gray-400" style={desktopLinkStyle}>
              Contact
            </a>
            <a href="/ReportLink" className="text-gray-400" style={desktopLinkStyle}>
              Report Link
            </a>
          </div>
        </div>

        <div className="md:hidden">
          <button
            className="hamburger-button w-11 h-11"
            style={{ fontSize: '1.5em' }} // Adjust the font size to make the icons larger
            onClick={toggleMenu}
          >
            {menuOpen ? '✕' : '☰'}
          </button>
        </div>
      </div>

      <div className={`md:hidden ${menuOpen ? 'block' : 'hidden'}`}>
        <div className="flex flex-col items-center">
          <a href="/" className="text-gray-400" style={mobileLinkStyle}>
            <strong>Home</strong>
          </a>
          <a href="/About" className="text-gray-400" style={mobileLinkStyle}>
            <strong>About</strong>
          </a>
          <a href="/ContactUs" className="text-gray-400 align-text ml-2" style={mobileLinkStyle}>
            <strong>Contact</strong>
          </a>
          <a href="/ReportLink" className="text-gray-400" style={mobileLinkStyle}>
            <strong>Report</strong>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
