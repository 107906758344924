import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

function Report() {
  const [nama, setNama] = useState('');
  const [alamatEmail, setAlamatEmail] = useState('');
  const [reportType, setReportType] = useState('Spamming');
  const [urlReport, setUrlReport] = useState('');
  const [alertClass, setAlertClass] = useState('hidden');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [firstNumber, setFirstNumber] = useState(0);
  const [secondNumber, setSecondNumber] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');

  const [otherDetails, setOtherDetails] = useState('');

  const generateMathProblem = () => {
    const num1 = Math.floor(Math.random() * 9) + 1;
    const num2 = Math.floor(Math.random() * 9) + 1;
    setFirstNumber(num1);
    setSecondNumber(num2);
  };

  useEffect(() => {
    generateMathProblem();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!nama || !alamatEmail || !urlReport) {
      setAlertClass('bg-red-500 text-white p-2 rounded-md my-4');
      setAlertMessage('Nama, Alamat Email, and URL Report are required');
      return;
    }

    if (parseInt(userAnswer) !== firstNumber + secondNumber) {
      setAlertClass('bg-red-500 text-white p-2 rounded-md my-4');
      setAlertMessage('Please answer the math question correctly');
      return;
    }

    setIsLoading(true);

    // Menggabungkan teks https://wsus.me/ dengan nilai yang dimasukkan
    const fullUrlReport = `https://wsus.me/${urlReport}`;

    emailjs
      .send('service_ulz2i0d', 'template_cybsbsd', {
        nama,
        alamatEmail,
        reportType: reportType === 'Other' ? `Other: ${otherDetails}` : reportType,
        urlReport: fullUrlReport,
      }, 'ZdnHN9k_VpS0eyd8f')
      .then((response) => {
        console.log('Report sent successfully:', response);
        setAlertClass('bg-green-500 text-white p-2 rounded-md my-4');
        setAlertMessage('Report sent successfully, Please check your email inbox or spam folder for the details.');
      })
      .catch((error) => {
        console.error('Report sending failed:', error);
        setAlertClass('bg-red-500 text-white p-2 rounded-md my-4');
        setAlertMessage('Report sending failed, Please Report Manual at <a href="mailto:report.link@wsus.me?subject=Report Link">report.link@wsus.me</a>');
      })
      .finally(() => {
        setIsLoading(false);
        setNama('');
        setAlamatEmail('');
        setReportType('Spamming');
        setUrlReport('');
        setOtherDetails('');
        generateMathProblem();
        setUserAnswer('');
      });
  };

  const handleClear = () => {
    setNama('');
    setAlamatEmail('');
    setReportType('Spamming');
    setUrlReport('');
    setOtherDetails('');
    setAlertClass('hidden');
    setAlertMessage('');
    generateMathProblem();
    setUserAnswer('');
  };

  return (
    <section className="bg-white p-4 rounded-md">
      <h1 className="text-2xl font-semibold mb-4 text-center">Form Report Link</h1>
      <p className="text-center text-gray-700 mb-4">
        Use the form to report URLs suspected of spam, phishing, or malware. Short URLs which redirect to malicious pages or download dangerous files are deleted.
      </p>
      <div className="w-full md:w-96 mx-auto">
        <form name="formContact" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="nama" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              id="nama"
              className="w-full p-2 border rounded-md"
              value={nama}
              onChange={(e) => setNama(e.target.value)}
              placeholder="Your Name"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="alamatEmail" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="alamatEmail"
              className="w-full p-2 border rounded-md"
              value={alamatEmail}
              onChange={(e) => setAlamatEmail(e.target.value)}
              placeholder="Your Email Address"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="reportType" className="block text-sm font-medium text-gray-700">
              Report Type
            </label>
            <select
              id="reportType"
              className="w-full p-2 border rounded-md"
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
            >
              <option value="Spamming">Spamming</option>
              <option value="Malware">Malware</option>
              <option value="Pornografi">Pornografi</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {reportType === 'Other' && (
            <div className="mb-4">
              <label htmlFor="otherDetails" className="block text-sm font-medium text-gray-700">
                Other Details
              </label>
              <textarea
                id="otherDetails"
                className="w-full p-2 border rounded-md"
                value={otherDetails}
                onChange={(e) => setOtherDetails(e.target.value)}
                placeholder="Provide details about the report type"
                required
              />
            </div>
          )}
          <div className="mb-4">
            <label htmlFor="urlReport" className="block text-sm font-medium text-gray-700">
              URL Report
            </label>
            <div className="flex items-center p-1 border rounded-md bg-white text-gray-700 text-sm">
              <div className=" flex items-center p-2 border rounded-md bg-white text-gray-700 text-sm">
                https://wsus.me/
              </div>
              <input
                type="text"
                id="urlReport"
                className="w-full flex-1 p-2 border rounded-md ml-1"
                value={urlReport}
                onChange={(e) => setUrlReport(e.target.value)}
                placeholder="e.g., spam"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label htmlFor="mathProblem" className="block text-sm font-medium text-gray-700">
              Solve the Math Problem: {firstNumber} + {secondNumber} =
            </label>
            <input
              type="text"
              id="mathProblem"
              className="w-full p-2 border rounded-md"
              value={userAnswer}
              onChange={(e) => setUserAnswer(e.target.value)}
              placeholder="Your Answer"
              required
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded-md mr-2 hover-bg-blue-700 border-b-0 shadow-none"
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send"}
            </button>
            <button
              type="button"
              className="bg-gray-300 p-2 rounded-md hover-bg-gray-400 border-b-0 shadow-none"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </form>
        <div className={alertClass}>
          <div dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
        </div>
      </div>
    </section>
  );
}

export default Report;
