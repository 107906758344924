/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './Loading';
import supabase from '../config/supaconfig';

function Redirector() {
  let { custom } = useParams();
  const [errorPage, setErrorPage] = useState(false);
  const [loading, setLoading] = useState(true);

  async function WindowRedirect(url) {
    window.location.replace(url);
  }

  async function DeleteData(c) {
    const { data, error } = await supabase
      .from('wsu')
      .delete()
      .match({ custom: c });
  }

  async function UpdateVisit(c, v) {
    const { data, error } = await supabase
      .from('wsu')
      .update({ visit: v + 1 })
      .match({ custom: c });
  }

  async function RedirectingMachine() {
    let { data, error } = await supabase
      .from('wsu')
      .select('*')
      .eq('custom', custom)
      .limit(1);
    data = data[0];
    if (!data) {
      setErrorPage(true);
      setLoading(false);
      return;
    }

    let now_ts = new Date().valueOf();
    UpdateVisit(custom, data.visit);
    if (data.deletion) {
      DeleteData(custom);
      WindowRedirect(data.url);
    } else if (data.expiration && data.expiration < now_ts) {
      DeleteData(custom);
      setTimeout(() => {
        setLoading(false);
        WindowRedirect('/');
      }, 2000);
    } else {
      setTimeout(() => {
        setLoading(false);
        WindowRedirect(data.url);
      }, 2000);
    }
  }

  useEffect(() => {
    RedirectingMachine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (errorPage) {
    return (
      <>
        <main className="font-sans bg-white">
          <div>
            <section className="bg-white mt-20">
              <div className="max-w-2xl px-6 text-center mx-auto">
                <div className="notfound">
                  <div className="notfound-404" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h1 className="font-montserrat font-bold text-4xl text-gray-900 mb-6">
                    <span style={{ fontSize: '7rem' }}>4</span>
                      <span
                      className="inline-block w-20 h-20 bg-cover bg-no-repeat mb-1"
                      style={{ backgroundImage: "url('https://colorlib.com/etc/404/colorlib-error-404-12/img/emoji.png')" }}
                    ></span><span style={{ fontSize: '7rem' }}>4</span>
                    </h1>
                    
                  </div>
                  <h2 className="font-montserrat font-bold text-base text-gray-900 mb-4">
                  <span style={{ fontSize: '20px' }}>Oops! The Page You're Looking for Was Not Found</span>
                  </h2>
                  <p className="font-montserrat text-base text-gray-800">
                    Sorry but the page you are looking for does not exist, have been removed, name changed, or is temporarily unavailable
                  </p>
                
                  <button
                    className="green-button transform transition-transform duration-300 hover:scale-95 mt-4"
                    style={{
                      background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)',
                      color: 'white',
                      padding: '5px 15px', // Sesuaikan padding
                      borderRadius: '25px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      WindowRedirect('/');
                    }}
                  >
                    Back to homepage
                  </button>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {/* ... (kode sebelumnya) */}
      <h2 className="font-montserrat font-bold text-base font-medium text-gray-800 mx-auto mt-2 mb-2">
        <button
          className="green-button transform transition-transform duration-300 hover:scale-95"
          style={{
            background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)',
            color: 'white',
            padding: '5px 15px', // Sesuaikan padding
            borderRadius: '25px',
            cursor: 'pointer',
          }}
          onClick={() => {
            WindowRedirect('/');
            const button = document.querySelector('.green-button');
            if (button) {
              button.classList.add('pressed');
              setTimeout(() => {
                button.classList.remove('pressed');
              }, 300);
            }
          }}
        >
          HOME
        </button>
      </h2>
    </>
  );
}

export default Redirector;
