import React from 'react';

const Privacy = () => {
    return (
        <div>
          <h1 className="text-2xl font-bold text-center mt-8">
            <span className="border-b-2 border-black">Privacy Policy</span>
          </h1>
          <div className="m-4 p-4 border border-gray-300 max-w-sm text-left rounded-lg mx-auto">
            <ul className="list-disc pl-4">
              <li>Your privacy is important to us. This Privacy Policy explains the personal information we collect and how we use it. We collect this information to analyze and enhance your experience with our services.</li>
              <li>
                <strong>Technology and Personal Data:</strong>
                <ul className="list-disc pl-4">
                  <li>This site may use tools to collect user information such as IP address, web browser, and your operating system to determine the number of visitors and understand how they use the site.</li>
                </ul>
              </li>
              <li>
                <strong>Cookies and Advertising Networks:</strong>
                <ul className="list-disc pl-4">
                  <li>We use third-party advertising companies to display ads when you visit our site. These companies, like our advertisers, may use cookies (small text files placed on your device) and similar technologies to collect information with the aim of displaying ads related to products and services of interest. This site may display ads served by Google advertising networks, which use cookies to determine user preferences and browsing behavior. Users can obtain more information about these cookies and privacy from the Google website.</li>
                  <li>Web browsers automatically accept cookies. However, if desired, you can modify your browser settings to reject cookies. Keep in mind that this may affect how you interact with our site and other sites on the internet.</li>
                </ul>
              </li>
              <li>
                <strong>Other Important Information:</strong>
                <ul className="list-disc pl-4">
                  <li>If you have questions about privacy or need more information, please contact our team.</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      );
    };

export default Privacy;
