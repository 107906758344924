/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { useAuth0 } from "@auth0/auth0-react";
import emailjs from 'emailjs-com';
import supabase from "../config/supaconfig";
import Loading from "./Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Generate() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(false);
  const [error, setError] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [successfulShorten, setSuccessfulShorten] = useState(false);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  const customInput = useRef(null);
  const urlInput = useRef(null);

  const [url, setUrl] = useState("");
  const [custom, setCustom] = useState(Math.random().toString(36).substring(2, 7));
  const [generated, setGenerated] = useState("");
  const [deletion, setDeletion] = useState(false);
  const [expiration, setExpiration] = useState(null);

  const host = "wsus.me";

  useEffect(() => {
    setCustom(randCustom);

    if (window.location.hostname === "short-url.triandii.me") {
      window.location.href = "http://wsus.me";
    }
  }, [user]);

  async function shortener() {
    setLoading(true);

    try {
      const selectedCustom = customInput.current.value || randCustom;

      const { data: customs, error: customError } = await supabase
        .from("wsu")
        .select("custom");
      const takenCustoms = customs.map((c) => c.custom);

      if (takenCustoms.includes(selectedCustom)) {
        setError("Custom name is taken");
        toast.error("Custom name is taken", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else if (!url.includes("http")) {
        setError('URL must include "http"');
        toast.error('URL must include "http"', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else if (
        selectedCustom.length !== 0 &&
        (selectedCustom.length < 3 || selectedCustom.length > 20)
      ) {
        setError("Custom name length must be between 3 and 20");
        toast.error("Custom name length must be between 3 and 20", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        setError("");
        const { data: insertData, error: insertError } = await supabase
          .from("wsu")
          .insert([
            {
              custom: selectedCustom,
              url: url,
              ts: Date.now(),
              owner: user ? user.email : "anonymous",
              visit: 0,
              deletion: deletion,
              expiration: expiration,
            },
          ]);

        setGenerated(`http://${host}/${selectedCustom}`);
        clean();
        setCustom(randCustom);

        toast.success("Shorten Successful", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setSuccessfulShorten(true);
        setShowSubscriptionPopup(true);
      }
    } catch (error) {
      console.error(error);
      setError(error.message || "An error occurred");
      toast.error(error.message || "An error occurred", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleSubscribe = () => {
    setShowSubscriptionPopup(true);
  };

  const handleSkipSubscription = () => {
    setShowSubscriptionPopup(false);
  };

  const handleSubscriptionSubmit = (e) => {
    e.preventDefault();

    setLoadingSubscription(true);

    emailjs
      .send(
        'service_7fe282v',
        'template_izam4kk',
        { to_email: subscriberEmail },
        'gKkFgFrqVcGx_fu3B'
      )
      .then(
        (response) => {
          console.log('Subscription email sent successfully:', response);
          toast.success('Subscription email sent successfully', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
        (error) => {
          console.error('Error sending subscription email:', error);
          toast.error('Error sending subscription email', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      )
      .finally(() => {
        setLoadingSubscription(false);
        setShowSubscriptionPopup(false);
      });
  };

  function QRCodeBox() {
    return generated.length >= 3 ? (
      <div className="qr inline-table">
        <QRCode renderAs="svg" value={generated} includeMargin={true} />
      </div>
    ) : null;
  }

  function clean() {
    customInput.current.value = "";
    urlInput.current.value = "";
  }

  const randCustom = Math.random().toString(36).substring(2, 7);

  function copyToClipboard(text) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    toast.success('Link copied to clipboard', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  return (
    <main className="font-sans bg-white">
      <section className="bg-white mt-20">
        <div className="max-w-2xl px-6 text-center mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
            <div className="bg-blue-300 p-2 text-black rounded" style={{ background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)', borderRadius: '9px' }}>
              <input
                ref={urlInput}
                className="bg-white text-black px-3 py-2 w-full rounded"
                onChange={(event) => setUrl(event.target.value)}
                type="text"
                placeholder="Enter the URL to be shortened here"
                style={{ borderRadius: '25px' }}
              />
            </div>

            <div className="bg-blue-300 p-2 text-black rounded" style={{ background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)', borderRadius: '9px' }}>
              {host}/{" "}
              <input
                ref={customInput}
                className="bg-white text-black px-3 py-2 rounded"
                type="text"
                placeholder="Custom (optional)"
                style={{ borderRadius: '25px' }}
              />
            </div>

            <div className="bg-blue-300 p-2 text-black rounded" style={{ background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)', borderRadius: '9px' }}>
              <span className="text-xs border-b border-gray-600">Pick Expiration Date</span>
              <input
                onChange={(e) => setExpiration(new Date(e.target.value).valueOf())}
                name="expiration"
                type="datetime-local"
                className="bg-white text-black px-3 py-2 rounded"
                placeholder="Pick Expiration Date"
                style={{ borderRadius: '25px' }}
              />
            </div>

            <div className="bg-blue-200 p-2 text-black rounded" style={{ background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)', borderRadius: '9px' }}>
              <label className="inline-flex items-center my-1">
                <span className="mr-4 text-black">Delete After Redirection</span>
                <input
                  onClick={() => setDeletion(!deletion)}
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-black"
                />
              </label>
            </div>
          </div>

          <button
            className="cursor-pointer text-black px-3 py-2 mt-3 rounded hover-bg-gray-400 hover-text-gray-600 active-text-gray-700 active-bg-gray-500"
            style={{
              background: 'linear-gradient(117.52deg, #FFCB05 0%, #C6168D 100%)',
              borderRadius: '20px'
            }}
            onClick={() => shortener()}
          >
            Shorten It
          </button>

          {loading && <Loading />}

          {error && (
            <div className="text-red-500 p-2 rounded bg-red-100 mt-4">
              {error}
            </div>
          )}

          {successfulShorten && (
            <div className="text-gray-500 p-2 rounded bg-gray-200 mt-4">
              {showSubscriptionPopup ? (
                <>
                  Subscription Popup:{" "}
                  <span
                    className="cursor-pointer text-blue-500"
                    onClick={handleSubscribe}
                  >
                    Subscribe
                  </span>{" "}
                  |{" "}
                  <span
                    className="cursor-pointer text-blue-500"
                    onClick={handleSkipSubscription}
                  >
                    Skip
                  </span>
                </>
              ) : null}
            </div>
          )}

          <p
            onClick={() => copyToClipboard(generated)}
            className="cursor-pointer bg-gray-000 hover-bg-gray-000 p-2 mt-4 text-gray-500"
          >
            <a
              target="_blank"
              href={generated.startsWith('http') ? generated : `http://${host}/${generated}`}
              rel="noreferrer"
            >
              {generated.startsWith('http') ? generated : `http://${host}/${generated}`}
            </a>
          </p>
          <QRCodeBox />
        </div>
      </section>

      {showSubscriptionPopup && (
        <div className="subscription-popup fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 border border-gray-300 rounded shadow-md z-50">
          <form onSubmit={handleSubscriptionSubmit} className="flex flex-col items-center">
            <label className="mb-4">
              Subscribe to updates via email:
              <input
                type="email"
                value={subscriberEmail}
                onChange={(e) => setSubscriberEmail(e.target.value)}
                required
                className="border border-gray-400 rounded px-2 py-1"
              />
            </label>
            <button type="submit" className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-700">
              {loadingSubscription ? "Subscribing..." : "Subscribe"}
            </button>
            <button
              type="button"
              onClick={handleSkipSubscription}
              className="mt-2 text-gray-500 hover:text-gray-700 cursor-pointer"
            >
              Skip
            </button>
          </form>
        </div>
      )}
    </main>
  );
}

export default Generate;
